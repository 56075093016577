import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-modal',
  templateUrl: './dashboard-modal.component.html',
  styleUrls: ['./dashboard-modal.component.scss']
})
export class DashboardModalComponent implements OnInit {
  @Input() modalTitle: string; 
  @Input() element: any;
  constructor() { }

  ngOnInit(): void {
    console.log('executed')
    console.log(this.element)
  }

}

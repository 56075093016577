<div class="stats-usage__item__chart" *ngIf="stats && stats.length > 0">
    <msc-chart-line [data]="data" [options]="options"></msc-chart-line>
</div>

<div class="stats-usage__item__actions" *ngIf="stats && stats.length > 0">
    <div class="actions-legend">
        <div class="legend-color" [ngStyle]="{'background-color': color}"></div>

        <div class="legend-title">
            {{ (legend || 'words.' + unit) | translate }}
        </div>
    </div>

    <i class="icon icon-expand" (click)="modalExpand.onOpen()"></i>
</div>

<div class="stats-usage__item__empty" *ngIf="stats && stats.length === 0">
    <div class="stats-usage__item__empty__icon bg-secondary-200">
        <i class="icon icon-file-remove text-secondary-500"></i>
    </div>

    <div class="stats-usage__item__empty__title">
        {{ 'empty.available.data' | translate }}
    </div>

    <div class="stats-usage__item__empty__desc">
        {{ 'empty.available.chart' | translate }}
    </div>
</div>

<msc-common-modal size="70vw" #modalExpand [title]="title">
    <ng-template>
        <msc-chart-line [data]="data" [options]="options"></msc-chart-line>
    </ng-template>
</msc-common-modal>

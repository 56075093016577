export class ExternalContent {
    id: number;
    title: string;
    description?: string;
    isDownloadable: number;
    skills?: any[];
    onDemand?: boolean;
    subscribed?: boolean;
    account: any;
    slug?: string;
    logo?: string;
    nextEvent?: string;
    duration?: number;
    picture?: string;
    url: string;
    progress?: number;
    archived?: boolean;
    type?: string;
    saved?: boolean;
    language?: any;
    request?: any;
    price?: number;
    provider?: any;
    createdAt?: any;
    subscriptionOrigins?: any;
    totalDuration?: any;

    constructor(externalContent: object) {
        this.assign(externalContent);
    }

    assign(externalContent: object): void {
        Object.assign(this, externalContent);
    }
}

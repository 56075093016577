import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Company, CompanyExperience } from '@lighty';
import { ExternalAppsService, StorageService } from '@services';

@Component({
    selector: 'msc-widget-contact',
    templateUrl: './contact.component.html'
})
export class WidgetContactComponent implements OnInit {
    @Input() experience: CompanyExperience;
    private me: any;
    private company: Company;

    constructor(private router: Router, private storageService: StorageService, private externalAppsService: ExternalAppsService) {}

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
    }

    conversationWith(user: any): void {
        if (user.conversationId) {
            this.router.navigate(['conversation', user.conversationId]);
        } else {
            this.storageService.set('conversationUsers', [user]);
            this.router.navigate(['conversation/create']);
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Replace a value by another one
 */
@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform {

    /**
     * Transform
     * @param {string} value - Value
     * @param {string} valueReplace - Value to replace
     * @param {string} replacer - New value
     * @return {string} Replaced value
     */
    transform(value: string, valueReplace: string, replacer: string = ''): string {
        return value.replace(new RegExp(valueReplace, 'g'), replacer);
    }
}

export class ManageInstance {
    id: number;
    title: string;
    description?: string;
    skills?: any[];
    onDemand?: boolean;
    subscribed?: boolean;
    slug?: string;
    logo?: string;
    nextEvent?: string;
    duration?: number;
    picture?: string;
    state?: string;
    isLocked?: boolean;
    validation?: any;
    stateLocked?: boolean;
    usedSeats?: number;
    limit?: number;
    needValidation?: boolean;
    progress?: number;
    requirement?: string;
    purpose?: string;
    target?: string;
    language?: string;
    resume?: any;
    events?: any;
    type?: string;

    constructor(instance: object) {
        this.assign(instance);
    }

    assign(instance: object): void {
        Object.assign(this, instance);
    }
}

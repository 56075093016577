import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
//
export interface CommonChartLineDatum {

}

@Component({
    selector: 'msc-common-chart-line',
    templateUrl: './chart-line.component.html',
    styleUrls: ['./chart-line.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonChartLineComponent {
    @Input() data: Array<CommonChartLineDatum> = [];
}

import { Directive, OnInit, HostListener, Input } from '@angular/core';
import { addClass, removeClass } from '@functions';

@Directive({
    selector: '[hovering]'
})
export class HoveringDirective implements OnInit {
    @Input('hovering') targetedElement: string;
    @Input('activeHovering') activatedClass: string = 'hovering';
    @Input('otherHovering') otherClass: string;
    @Input('deferOtherHovering') deferOther: number;
    @Input('deferDisableHovering') deferDisable: number = 250;
    private timeOut: any = {
        other: null,
        disable: null
    };

    constructor() {}

    ngOnInit(): void {}

    @HostListener('mouseover')
    onMouseOver(): void {
        addClass(this.targetedElement, this.activatedClass);

        clearTimeout(this.timeOut.disable);

        if (this.otherClass) {
            if (this.deferOther) {
                this.timeOut.other = setTimeout(() => {
                    addClass(this.targetedElement, this.otherClass);
                    clearTimeout(this.timeOut.other);
                }, this.deferOther);
            } else {
                addClass(this.targetedElement, this.otherClass);
            }
        }
    }

    @HostListener('mouseout')
    onMouseOut(): void {
        this.timeOut.disable = setTimeout(() => {
            removeClass(this.targetedElement, this.activatedClass);
            removeClass(this.targetedElement, this.otherClass);

            clearTimeout(this.timeOut.disable);
        }, this.deferDisable);
    }
}

export const environment = {
    production: true,
    demo: true,
    maintenance: false,
    name: {
        short: 'dev',
        long: 'dev'
    },
    integrations: {
        keySecret: 'w3DO62KV85U68WgJ',
        webinarKey: 'zDOZ9n6tY1kz6lFNbc8TsGHDffEtZn0w',
        beamer: 'UYnzpRBS27242'
    },
    payments: {
        stripeKey: 'pk_test_K45FsLAqvD754RIG0PKjRDTd',
        weavr: 'apl6rkn0HRcBc+IejpAADA=='
    },
    analytics: {
        google: 'UA-112169318-4',
        mixpanel: '2de0b492c5dfab39cb2a95d19a249b43',
        fullstory: '4HEW5'
    },
    domain: 'deltablue.com',
    tokenName: 'token_msc_staging',
    tokenSessionName: 'token_session',
    refreshTokenName: 'refresh_token_staging',
    envVar: {
        AUTH_URL: "https://auth.myskillcamp.com.deltabluecdn.com",
        API_URL: "https://api-upgraded.myskillcamp.com.deltabluecdn.com/api",
        REPORTING_URL: "https://reporting-clone-f9cb377f5501.deltablue.io/api/v2",
        STUDIO_URL: "https://studio.myskillcamp.com.deltabluecdn.com/",
        PLAYER_URL: "https://player.myskillcamp.com.deltabluecdn.com/",
        APP_URL: "https://app.myskillcamp.com.deltabluecdn.com/",
        CHAT_URL: "https://chat.myskillcamp.com.deltabluecdn.com",
        CLOUD_URL: "https://cloud.myskillcamp.com.deltabluecdn.com",
        CSS_URL: "https://cdn.myskillcamp.com.deltabluecdn.com"
    }
};

import { Directive, OnInit, OnDestroy, HostListener, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { addClass, removeClass } from '@functions';

@Directive({
    selector: '[aside]'
})
export class AsideDirective implements OnInit, OnDestroy {
    @Input('asideActive') classActive: string = 'active';
    @Input('overlayLight') overlayLight: boolean = false;
    @Input('closeByUser') closeByUser: EventEmitter<any> = new EventEmitter();
    @Input('globalClose') globalClose: boolean = false;
    @Output('aside') close: EventEmitter<any> = new EventEmitter();
    private active: boolean = false;

    constructor(private element: ElementRef) {}

    ngOnInit(): void {
        addClass('.app-main', 'disable-overflow');
        addClass('.overlay', 'active');
        if (this.overlayLight) {
            addClass('.overlay', 'active--light');
        }
        this.deferActive(true);

        this.closeByUser.subscribe(() => {
            this.element.nativeElement.className = this.element.nativeElement.className.replace(' ' + this.classActive, '');
            this.deferActive(false);
        });
    }

    ngOnDestroy(): void {
        removeClass('.app-main', 'disable-overflow');
        removeClass('.overlay', 'active');
        if (this.overlayLight) {
            removeClass('.overlay', 'active--light');
        }
    }

    @HostListener('document:click', ['$event'])
    onClickout(event: any): void {
        if (event.target.className === 'overlay active' || event.target.className === 'overlay active active--light') {
            this.element.nativeElement.className = this.element.nativeElement.className.replace(' ' + this.classActive, '');
            this.deferActive(false);
        }

        if (this.active && this.globalClose && (event.target.closest('.header') || event.target.closest('.sidebar'))) {
            this.element.nativeElement.className = this.element.nativeElement.className.replace(' ' + this.classActive, '');
            this.deferActive(false);
        }
    }

    private deferActive(active: boolean): void {
        const ms = (active) ? 100 : 300;
        const timeOut = setTimeout(() => {
            this.active = active;
            if (active) {
                this.element.nativeElement.className += ' ' + this.classActive;
            } else {
                this.close.emit();
            }
            clearTimeout(timeOut);
        }, ms);
    }
}

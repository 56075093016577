<div class="content-stats content-stats-list-users">
    <div class="flex flex-col gap-4">
        <div class="content-stats__breadcrumb" [ngClass]="{'active': meta.course || meta.section}">
            <div class="content-stats__avatar" (click)="learners()">
                <msc-common-avatar [label]="meta.account?.name" [pictureURL]="meta.account?.picture ? (meta.account?.picture + '?size=128') : null"></msc-common-avatar>

                <div class="content-stats__profile">{{ 'stats.participants-list' | translate }}</div>
                <i class="icon icon-arrow-ios-right"></i>
            </div>

            <div class="content-stats__stats-title" (click)="user()">
                {{ meta.account.name | truncate: 30 }}
                <i class="icon icon-arrow-ios-right" *ngIf="meta.course || meta.section"></i>
            </div>

            <div class="content-stats__stats-title" *ngIf="meta.course?.title" (click)="journey()">
                {{ meta.course.title | truncate: 30 }}
                <i class="icon icon-arrow-ios-right" *ngIf="meta.section"></i>
            </div>

            <div class="content-stats__stats-title" *ngIf="meta.section?.title" (click)="course()">
                {{ meta.section.title | truncate: 30 }}
                <i class="icon icon-arrow-ios-right"></i>
            </div>

            <div class="content-stats__stats-title" *ngIf="meta.activity">
                <i class="icon icon-checkmark-square"></i>
                {{ (meta.activity.title || ('stats.content.type-exercise' | translate)) | truncate: 30 }}
            </div>
        </div>

        <div class="flex justify-around items-center p-6 bg-white gap-4">
            <p class="text-secondary-400">{{ (header.subscribed ? 'words.subscribed' : 'words.unsubscribed') | translate }}</p>

            <p>
                {{ 'words.course-hours' | translate }} :
                <span class="text-secondary-400">{{ totalDuration | duration }}</span>
            </p>

            <div class="flex space-x-4 items-center">
                <p>{{ 'words.progress' | translate }}</p>
                <msc-chart-pie [value]="header.progress" [size]="'small'" *ngIf="refresh"></msc-chart-pie>
            </div>

            <div class="flex space-x-4 items-center">
                <p>{{ 'words.score' | translate }}</p>
                <msc-chart-pie [value]="header.score" [size]="'small'" *ngIf="refresh"></msc-chart-pie>
            </div>

            <msc-button *ngIf="me.id !== meta.account.id" class="msc-button--outline" icon="icon-message-circle" (click)="conversation()">
                {{ 'words.discuss' | translate }}
            </msc-button>
        </div>

        <div class="flex">
            <msc-common-search class="tiny" trigger="auto" [placeholder]="'common.search' | translate" (searchEvent)="search($event.value)"></msc-common-search>
        </div>
    </div>

    <div class="dynamic-table table-stats-question">
        <div class="dynamic-table__head">
            <div class="dynamic-table__cell cell-title">
                <msc-sort [reset]="activeSort" [label]="'words.name' | translate" (onSort)="sorting('title', $event)"></msc-sort>
            </div>

            <div class="dynamic-table__cell">
                <msc-sort [reset]="activeSort" [label]="'words.course-hours' | translate" (onSort)="sorting('duration', $event)"></msc-sort>
            </div>

            <div class="dynamic-table__cell">
                <msc-sort [reset]="activeSort" [label]="'words.progress-average' | translate" (onSort)="sorting('progress', $event)"></msc-sort>
            </div>

            <div class="dynamic-table__cell">
                <msc-sort [reset]="activeSort" [label]="'words.score' | translate" (onSort)="sorting('score', $event)"></msc-sort>
            </div>
        </div>

        <div class="dynamic-table__body" *ngIf="parameters.entity !== 'course' || (parameters.entity === 'course' && filtering)">
            <div class="dynamic-table__row row-radius row-shadow row--state" *ngFor="let item of items" [ngClass]="{'row--succeed': item.progress === 100, 'row--in-progress': item.progress > 0 && item.progress !== 100, 'row-hover': item.context === 'exercise' || item.context === 'course'}" (click)="contentNavigate(item)">
                <div class="dynamic-table__cell cell-title cell-with-icon flex">
                    <i [attr.class]="getIcon(item)"></i>
                    <p>
                        {{ 'stats.content.type-' + (item.context || item.type) | translate }} <br/>
                        <span>{{ item.title }}</span>
                    </p>
                </div>

                <div class="dynamic-table__cell">
                    {{ item.duration | duration }}
                </div>

                <div class="dynamic-table__cell cell-chart">
                    <msc-chart-pie [value]="item.progress" [size]="'small'"></msc-chart-pie>
                </div>

                <div class="dynamic-table__cell cell-chart">
                    <msc-chart-pie [value]="item.score" [size]="'small'"></msc-chart-pie>
                </div>

                <div class="dynamic-table__cell cell-actions" *ngIf="item.context === 'exercise' || item.context === 'course'">
                    <a>
                        <i class="cell-actions__icon icon icon-arrow-ios-right"></i>
                    </a>
                </div>
            </div>
        </div>

        <div class="dynamic-table__body" *ngIf="parameters.entity === 'course' && !filtering">
            <div *ngFor="let group of groupedItems">
                <div class="table__section-line">
                    <div class="table__section-line-text">{{ group.title }}</div>
                </div>
                <div class="dynamic-table__row row-radius row-shadow row--state" *ngFor="let item of group.items" [ngClass]="{'row--succeed': item.progress === 100, 'row--in-progress': item.progress > 0 && item.progress !== 100, 'row-hover': item.context === 'exercise' || item.context === 'course'}" (click)="contentNavigate(item)">
                    <div class="dynamic-table__cell cell-title cell-with-icon flex">
                        <i [attr.class]="getIcon(item)"></i>
                        <p>
                            {{ 'stats.content.type-' + (item.context || item.type) | translate }} <br/>
                            <span>{{ item.title }}</span>
                        </p>
                    </div>

                    <div class="dynamic-table__cell">
                        {{ item.duration | duration }}
                    </div>

                    <div class="dynamic-table__cell cell-chart">
                        <msc-chart-pie [value]="item.progress" [size]="'small'"></msc-chart-pie>
                    </div>

                    <div class="dynamic-table__cell cell-chart">
                        <msc-chart-pie [value]="item.score" [size]="'small'"></msc-chart-pie>
                    </div>

                    <div class="dynamic-table__cell cell-actions" *ngIf="item.context === 'exercise' || item.context === 'course'">
                        <a>
                            <i class="cell-actions__icon icon icon-arrow-ios-right"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

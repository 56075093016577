import { Injectable, EventEmitter } from '@angular/core';

/**
 * Toast servive
 */
@Injectable({providedIn: 'root'})
export class ToastService {
    /**
     * Toast event emitter
     */
    private toast: EventEmitter<any> = new EventEmitter();

    /**
     * Listener
     * @return {EventEmitter<any>} Toast emitter
     */
    listen(): EventEmitter<any> {
        return this.toast;
    }

    /**
     * Push toast inside emitter
     * @param {string} message - Message
     * @param {string} type - Type
     * @param {any} translateValue - Translate value
     * @param {number} duration - Duration
     * @param {any} options - More options
     */
     push(message: string, type: string = 'success', translateValue: any = null, duration: number = 5000, options: any = null): void {
        this.toast.emit({event: 'push', data: {message, type, translateValue, duration, options}});
    }

    /**
     * Dismiss a specific toast
     * @param {string} id - Identifier
     */
    dismiss(id: string): void {
        this.toast.emit({event: 'dismiss', data: {id}});
    }
}

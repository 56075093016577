import { Pipe, PipeTransform } from '@angular/core';

/**
 * Clean HTML element from a value
 */
@Pipe({
    name: 'clean'
})
export class CleanPipe implements PipeTransform {

    transform(value: string): any {
        if (value) {
            return value.replace('&nbsp;', '').replace(/<[^>]*>/g, '');
        }
        return null;
    }
}

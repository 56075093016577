import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmitterService } from '@services';

@Component({
    selector: 'msc-learn-detail-instance',
    templateUrl: './instance.component.html'
})
export class LearnDetailInstanceComponent implements OnInit {
    public hasCorrections: boolean;

    constructor(private route: ActivatedRoute, private emitterService: EmitterService) {}

    ngOnInit(): void {
        this.hasCorrections = this.route.snapshot.data.corrections
            .reduce((accumulator, current) => [...accumulator, current.corrections.filter(c => c.corrections.length > 0)], []).length > 0;
        this.emitterService.set('has-corrections', this.hasCorrections);
    }
}

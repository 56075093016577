<div class="block-settings__select block-settings__select--no-margin" [ngStyle]="{'margin': !hasLabel ? 0 : null}">
    <ng-container *ngIf="hasLabel && !label">
        <label *ngIf="type === 'content'">
            {{ 'words.learning-languages' | translate }}
        </label>
        <label *ngIf="type === 'interface'">
            {{ 'words.interface-language' | translate }}
        </label>
    </ng-container>
    <ng-container *ngIf="label">
        <label>
            {{ label }}
        </label>
    </ng-container>

    <div *ngIf="!multiSelect" class="select" [ngClass]="{'active': active}" (click)="toggle()">
        <div class="select__lang" *ngIf="currentLanguage">
            <img *ngIf="type === 'interface'" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{currentLanguage.code}}.svg">
            {{ currentLanguage.name }}
        </div>

        <div class="dropdown dropdown-learning-language" [ngClass]="{'active': active, 'dropdown-overflow': overflow}" [(autoClose)]="active">
            <div class="dropdown-learning-language__content">
                <input *ngIf="overflow" class="dropdown-learning-language__filter" type="text" [placeholder]="'placeholder.search' | translate"
                    [(ngModel)]="filterInput" (ngModelChange)="filterLanguages($event)" (click)="$event.stopPropagation()" />
                <div *ngFor="let lang of filteredLanguages" class="dropdown-learning-language__flag"
                    (click)="onSelect(lang)">
                    <img *ngIf="type === 'interface'" src="https://static.myskillcamp.com/images/icon/icon-camp/flag_{{lang.code}}.svg">
                    {{lang.name}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="multiSelect" class="select" [ngClass]="{'active': active}" (click)="toggle()">
        <div class="select__tag select__camp">
            <ng-container *ngFor="let selectedLanguage of uiLanguageSelection">
                <msc-tag [color]="'orange'" [text]="selectedLanguage.name"></msc-tag>
            </ng-container>
        </div>

        <div class="dropdown dropdown-learning-language" [ngClass]="{'active': active, 'dropdown-overflow': overflow}" [(autoClose)]="active">
            <input *ngIf="overflow" class="dropdown-learning-language__filter" type="text" [placeholder]="'placeholder.search' | translate"
                [(ngModel)]="filterInput" (ngModelChange)="filterLanguages($event)" (click)="$event.stopPropagation()" />
            <label class="checkbox" *ngFor="let lang of filteredLanguages">
                <msc-common2-checkbox [value]="lang.checked" [label]="lang.name" (changeEvent)="updateSelectedLanguages()"></msc-common2-checkbox>
            </label>
        </div>
    </div>
</div>

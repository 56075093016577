import { Pipe, PipeTransform } from '@angular/core';

/**
 * Currency pipe
 */
@Pipe({
    name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

    transform(price: number|string, currency: string = '€'): any {
        return price + ' ' + currency;
    }
}

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
//
export interface CommonChartBarDatum {

}

@Component({
    selector: 'msc-common-chart-bar',
    templateUrl: './chart-bar.component.html',
    styleUrls: ['./chart-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonChartBarComponent {
    @Input() data: Array<CommonChartBarDatum> = [];
}

import { Account } from '../account';

export class KnowledgeSkill {
    id: number;
    name: string;
    usage: any;
    visible: boolean;
    validated: boolean;
    createdAt: Date;
    suggestion: KnowledgeSkill;
    thematic: boolean;
    translations: any;
    validatedBy: any;
    validatedAt: Date;
    account: Account;

    constructor(skill: object) {
        this.assign(skill);
    }

    assign(skill: object): void {
        Object.assign(this, skill);
    }
}

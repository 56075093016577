export class Template {
    id: number;
    title: string;
    description?: string;
    skills?: any[];
    onDemand?: boolean;
    subscribed?: boolean;
    slug?: string;
    nextEvent?: string;
    duration?: number;
    picture?: string;
    courseCamps?: any[];
    lang?: string;
    language?: any;
    requirement?: string;
    target?: string;
    purpose?: string;
    instances?: any[];
    savableId?: number;
    saved?: boolean;
    state?: string;
    type?: string;
    provider?: any;
    totalDuration?:any;

    constructor(template: object) {
        this.assign(template);
    }

    assign(template: object): void {
        Object.assign(this, template);
    }
}

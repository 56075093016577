import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LanguageProvider } from '@lighty';
import { Language } from '@interfaces';
import * as _ from 'lodash';

@Component({
    selector: 'msc-language-dropdown',
    templateUrl: './language-dropdown.component.html'
})
export class LanguageDropdown implements OnInit {
    @Input() type: 'interface' | 'content' = 'content';
    @Input() currentLanguage: Language = null;
    @Input() languageSelection: Language[] = null;
    @Input() multiSelect = true;
    @Input() overflow = false;
    @Input() hasLabel = true;
    @Input() label;
    @Output() select: EventEmitter<any> = new EventEmitter<any>();

    public active: boolean = false;
    public languages: Language[] = [];
    public filteredLanguages: Language[] = [];
    public uiLanguageSelection: Language[] = [];

    constructor(private languageProvider: LanguageProvider) { }

    ngOnInit(): void {
        this.getLanguages();
    }

    public getLanguages(selectedLanguage?: Language) {
        return this.languageProvider.get(this.type, selectedLanguage).subscribe((langs) => {
            this.languages = _.cloneDeep(langs).filter(lang => lang.name);

            if (this.type === 'content') {
                this.initSelection();
            }

            this.filteredLanguages = this.languages;
            if (selectedLanguage) {
                this.currentLanguage = this.languages.filter(lang => lang.id === selectedLanguage.id)[0];
            }
        });
    }

    initSelection(): void {
        if (this.languageSelection && this.languageSelection.length > 0) {
            this.languageSelection.forEach((language: Language) => {
                (this.languages[this.languages.findIndex((lang: Language) => lang.id === language.id)] as any).checked = true;
            })
            this.uiLanguageSelection = [...this.languageSelection];
        }
    }

    updateSelectedLanguages(): void {
        this.languageSelection = this.languages.filter((language: any) => {
            return language.checked === true;
        }).map((lang: Language) => ({
            id: lang.id,
            name: lang.name,
            code: lang.code
        }));
        this.uiLanguageSelection = [...this.languageSelection];
        this.select.emit(this.languageSelection);
    }

    toggle() {
        this.active = !this.active;
    }

    filterLanguages(event) {
        if (typeof event === 'string') {
            this.filteredLanguages = this.languages.filter(a => a?.name.toLowerCase().startsWith(event.toLowerCase()));
        }
    }

    onSelect(lang: Language): void {
        this.currentLanguage = lang;
        this.select.emit(lang);
    }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'msc-common-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CommonCalendarComponent {

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionCollection } from '@classes';
import { Pagination, Company, StatsProvider } from '@lighty';
import { StorageService } from '@services';
import { HttpHelper, DataHelper } from '@helpers';

@Component({
    selector: 'msc-manage-instance-learner-stats',
    templateUrl: './stats.component.html',
    styleUrls: ['./stats.component.scss']
})
export class ManageInstanceLearnerStatsComponent implements OnInit, OnDestroy {
    private company: Company;
    private instance: any;
    private subscriptionCollection: SubscriptionCollection = new SubscriptionCollection();
    public me: any;
    public meta: any;
    public header: any;
    public items: any;
    public totalDuration: any;
    public groupedItems: any;
    public pagination: Pagination;
    public query: string;
    public loading: boolean = false;
    public sort: any;
    public activeSort: boolean = false;
    public filtering: boolean = false;
    public refresh: boolean = false;
    public parameters: any = {
        entity: null,
        entityId: null,
        display: null,
        courseId: null
    };

    constructor(private route: ActivatedRoute, private router: Router, private statsProvider: StatsProvider,
        private storageService: StorageService) { }

    ngOnInit(): void {
        this.me = this.storageService.get('me');
        this.company = this.storageService.get('company');
        this.instance = this.storageService.get('content');

        this.subscriptionCollection.subscribe = this.route.data.subscribe((data) => {
            this.refresh = false;
            this.meta = data.stats.meta;
            this.header = data.stats.header;
            this.items = data.stats.items;
            this.totalDuration = this.items.reduce((acc, item) => acc += item.duration, 0);
            this.groupedItems = null;
            this.filtering = false;
            if (this.router.url.indexOf('course') > -1) {
                this.groupedItems = DataHelper.groupBy(data.stats.items, 'sectionTitle');
            }
            this.pagination = data.stats.pagination;

            this.resetSort(false);

            const timeOut = setTimeout(() => {
                this.refresh = true;
                clearTimeout(timeOut);
            }, 150);
        });

        this.subscriptionCollection.subscribe = this.route.params.subscribe((params) => {
            const courseId = this.instance.id;
            this.parameters = { ...params, courseId };
            console.log(this.parameters);
            
        });
    }

    ngOnDestroy(): void {
        this.subscriptionCollection.unsubscribe();
    }

    search(query?): void {
        this.query = query;
        const params = HttpHelper.cleanParams({
            entity: this.parameters.entity,
            entityId: this.parameters.entityId,
            display: this.parameters.display,
            orderBy: this.sort.name,
            orderWay: this.sort.type,
            q: this.query,
            account_id: this.meta.account.id
        });

        this.statsProvider.get(params).subscribe((data) => {
            this.items = data.items;
            this.pagination = data.pagination;
            this.filtering = this.activeSort || !!this.query;
        });
    }

    loadMore(): void {
        this.loading = true;

        const params = HttpHelper.cleanParams({
            entity: this.parameters.entity,
            entityId: this.parameters.entityId,
            display: this.parameters.display,
            q: this.query,
            page: this.pagination.currentPage + 1
        });

        this.statsProvider.get(params).subscribe((data) => {
            this.items = this.items.concat(data.items);
            this.pagination = data.pagination;
            this.loading = false;
        });
    }

    conversation(): void {
        this.storageService.set('conversationUsers', [this.meta.account]);
        this.router.navigate(['conversation/create']);
    }

    private navigate(entity: string, entityId: number, display: string): void {
        this.router.navigate(['manage', this.company.slug, 'contents', this.instance.type === 'course_instance' ? 'template' : 'journey', this.instance.id, 'learners', this.meta.account.id, 'stats', entity, entityId, display]);
    }

    contentNavigate(item: any): void {
        switch (item.context) {
            case 'course':
                this.navigate('course', item.instanceId, 'activities');
                break;
            case 'exercise':
                this.navigate('activity', item.sectionContentId || item.id, 'questions');
                break;
        }
    }

    getIcon(item: any): string {
        switch (item.context) {
            case 'course':
                return 'icon icon-cube';
            case 'media':
                return 'icon icon-video';
            case 'cheatsheet':
                return 'icon icon-file-text';
            default:
                return 'icon icon-checkmark-square';
        }
    }

    learners(): void {
        this.router.navigate(['manage', this.company.slug, 'contents', this.instance.type === 'course_instance' ? 'template' : 'journey', this.instance.id, 'learners']);
    }

    user(): void {
        if (this.instance.type === 'traject') {
            this.navigate('traject', this.instance.id, 'contents');
        }
    }

    journey(): void { }

    course(): void {
        this.navigate('course', this.meta.course.id, 'activities');
    }

    resetSort(search: boolean = true): void {
        this.sort = {
            name: '',
            type: null
        };
        this.activeSort = false;
        this.filtering = false;
        if (search) {
            this.search();
        }
    }

    sorting(category: string, type: string): void {
        this.sort.name = type ? category : null;
        this.sort.type = type;
        this.activeSort = true;
        this.search();
    }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'msc-loading',
    templateUrl: './loading.component.html'
})
export class LoadingComponent {
    @Input() loading: boolean = false;

    constructor() {}
}

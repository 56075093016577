import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManageCorrectionProvider, Company } from '@lighty';
import { StorageService } from '@services';

@Component({
    selector: 'msc-manage-instance-correction',
    templateUrl: './correction.component.html'
})
export class ManageInstanceCorrectionComponent implements OnInit {
    private company: Company;
    private instance: any;

    constructor(private route: ActivatedRoute, private correctionProvider: ManageCorrectionProvider, private storageService: StorageService) {}

    ngOnInit(): void {
        this.company = this.storageService.get('company');
        this.instance = this.route.parent.snapshot.data.instance;
    }
}

export class Instance {
    id: number;
    title: string;
    description?: string;
    skills?: any[];
    onDemand?: boolean;
    subscribed?: boolean;
    slug?: string;
    nextEvent?: string;
    duration?: number;
    picture?: string;
    state?: string;
    isLocked?: boolean;
    validation?: any;
    usedSeats?: number;
    limit?: number;
    needValidation?: boolean;
    progress?: number;
    requirement?: string;
    purpose?: string;
    target?: string;
    language?: any;
    resume?: any;
    events?: any;
    type?: string;
    locked?: boolean;
    playerLight?: any;
    provider?: any;
    subscriptionOrigins?: any;
    subscriptionState?: string;
    totalDuration?: any;

    constructor(instance: object) {
        this.assign(instance);
    }

    assign(instance: object): void {
        Object.assign(this, instance);
    }
}

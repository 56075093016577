export class Mentoring {
    id: number;
    url: string;
    originalUrl?: string;
    pictureUrl?: string;
    title: string;
    type: string;
    storage: string;

    constructor(mentoring: object) {
        this.assign(mentoring);
    }

    assign(mentoring: object): void {
        Object.assign(this, mentoring);
    }
}

export class Journey {
    id: number;
    title: string;
    description?: string;
    skills?: any[];
    onDemand?: boolean;
    subscribed?: boolean;
    slug?: string;
    nextEvent?: string;
    duration?: number;
    picture?: string;
    trajectUserId?: number;
    resume?: any;
    events?: any;
    hasToSubscribe?: boolean;
    instances?: any;
    lang?: string;
    language?: any;
    contents?: any[];
    isLocked?: boolean;
    progress?: number;
    session?: any;
    saved?: boolean;
    usedSeats?: number;
    usersLimit?: number;
    contactIds?: number[];
    provider?: any;
    subscriptionOrigins?: any;
    totalDuration?: any;
    pagination?: any;

    constructor(journey: object) {
        this.assign(journey);
    }

    assign(journey: object): void {
        Object.assign(this, journey);
    }
}

<div class="flex flex-col space-y-4 items-center bg-white shadow-md py-8 px-4 justify-center text-center rounded" style="height:400px">

    <ng-container *ngIf="experience && experience.contact; else noContact">
        <h2>{{ 'words.a-question' | translate }}</h2>

        <div class="flex justify-center">
            <msc-common-avatar class="large" [label]="experience.contact?.name"
                [pictureURL]="experience.contact?.picture ? (experience.contact?.picture + '?size=128') : null">
            </msc-common-avatar>
        </div>

        <p>{{ experience.contact.name }}</p>
        <small *ngIf="experience.contact.headline">{{ experience.contact.headline }}</small>

        <msc-button class="msc-button--primary" palette="secondary" (click)="conversationWith(experience.contact)">
            {{ 'words.contact-us' | translate }}
        </msc-button>
    </ng-container>

    <ng-template #noContact>
        <msc-empty-state [title]="'words.contact'" [description]="'empty.contact'" [cypressHook]="'contact'">
        </msc-empty-state>
    </ng-template>
</div>

import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'msc-avatar',
    templateUrl: './avatar.component.html'
})
export class AvatarComponent implements OnInit {
    @Input() class: string = 'avatar';
    @Input() url: string;
    @Input() size: number = 128;

    constructor() {}

    ngOnInit(): void {
        this.url += this.url ? '?size=' + this.size : null;
    }
}

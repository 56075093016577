export class Skill {
    id: number;
    level: number;
    name: string;

    constructor(skill: object) {
        this.assign(skill);
    }

    assign(skill: object): void {
        Object.assign(this, skill);
    }
}


<msc-common-modal size="30vw" #modalAdd [title]="'words.dashboard-settings-' + modalTitle | translate">
    <ng-template>
        <msc-input [label]="'words.name' | translate"
        [placeholder]="'words.name' | translate"
        [(NgModel)]="dashboardItemName"
        ></msc-input>
        <msc-input [label]="'words.description' | translate"
        [placeholder]="'words.description' | translate"
        [(NgModel)]="dashboardItemDescription"
        ></msc-input>
        <msc-input [label]="'words.url' | translate"
        [placeholder]="'words.url' | translate"
        [(NgModel)]="dashboardItemUrl"
        ></msc-input>

        <div class="flex items-center mt-5 space-x-5 justify-end">
            <msc-button palette="primary" (click)="modalAdd.onClose()">{{'common.cancel' | translate}}
            </msc-button>
            <msc-button class="msc-button--primary" palette="primary" (click)="createDashboard();">
                {{'common.save' | translate}}</msc-button>
        </div>
    </ng-template>
</msc-common-modal>